<template>
  <section class="relative w-full max-w-md bg-white p-8 rounded-lg shadow-md z-10">
    <h2 class="text-center text-3xl font-extrabold text-gray-900">Změna hesla</h2>
    <form @submit.prevent="resetPassword" class="mt-8 space-y-6">
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700">Nové heslo</label>
        <div class="mt-1">
          <input id="password" name="password" type="password" v-model="newPassword" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
        </div>
      </div>
      <div class="mt-4">
        <label for="confirmPassword" class="block text-sm font-medium text-gray-700">Potvrďte nové heslo</label>
        <div class="mt-1">
          <input id="confirmPassword" name="confirmPassword" type="password" v-model="confirmPassword" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
        </div>
      </div>
      <div v-if="errorMessage" class="mt-4 text-red-500 text-sm">
        {{ errorMessage }}
      </div>
      <div class="mt-4">
        <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lightBlue-600 hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500">
          Změnit heslo
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ResetPasswordToken',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
    };
  },
  methods: {
    resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = 'Hesla se neshodují.';
        return;
      }
      const token = this.$route.params.token;
      const apiUrl = process.env.VUE_APP_API_URL;
      axios.post(`${apiUrl}/api/auth/reset-password`, { token, newPassword: this.newPassword })
        .then(() => {
          alert('Heslo bylo úspěšně změněno.');
          this.$router.push('/auth/login'); // Přesměrování na přihlašovací stránku
        })
        .catch(error => {
          console.error('Změna hesla selhala:', error);
          this.errorMessage = 'Změna hesla selhala. Zkuste to prosím znovu.';
        });
    }
  }
}
</script>

<style scoped>
/* Notus Tailwind CSS styles */
</style>
