<template>
  <div class="min-h-screen bg-cover flex flex-col" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="w-full flex justify-between items-center px-4 py-4 bg-transparent absolute top-0 left-0 right-0">
      <h1 class="text-3xl font-extrabold text-center w-full text-blueGray-700">Coach Dashboard</h1>
    </div>
    <div class="flex flex-col justify-start sm:px-6 lg:px-8 flex-grow mt-20">
      <div class="sm:mx-auto sm:w-full sm:max-w-7xl">
        <div class="relative mb-4">
          <p class="text-center text-blueGray-600">
            Vítejte, {{ user.name ? user.name.split(' ')[0] : '' }}
            <router-link to="/profile" class="text-lightBlue-600 hover:underline">(Můj profil)</router-link>
          </p>
        </div>
        <div class="w-full">
          <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
            <div class="flex justify-between items-center mb-4">
              <h3 class="text-xl font-semibold text-blueGray-700">Doporučení</h3>
              <div class="flex items-center space-x-2">
                <label class="block text-sm font-medium text-blueGray-700">Status:</label>
                <select v-model="activeFilter.status" class="mt-1 block w-full shadow-sm sm:text-sm border-blueGray-300 rounded-md">
                  <option value="active">Aktivní</option>
                  <option value="pending">Nevyřízené</option>
                  <option value="inprogress">V procesu</option>
                  <option value="completed">Dokončeno</option>
                  <option value="rejected">Zamítnuto</option>
                  <option value="archive">Archivováno</option>
                  <option value="">Všechny</option>
                </select>
              </div>
            </div>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-blueGray-200">
                <thead>
                  <tr>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Jméno uživatele</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Jméno kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Email kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Status</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Hodnota obchodu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Akce</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Vytvořeno</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Aktualizováno</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-blueGray-200">
                  <tr v-for="recommendation in filteredRecommendations" :key="recommendation.id">
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.user_name }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_first_name }} {{ recommendation.contact_last_name }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_email }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ translateStatus(recommendation.status) }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.deal_value || 'No Value' }}</td>
                    <td class="px-6 py-4 whitespace-nowrap flex space-x-2">
                      <button @click="editRecommendation(recommendation)" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                        Edit
                      </button>
                      <button @click="viewRecommendation(recommendation)" class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Zobrazit
                      </button>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(recommendation.created_at) }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(recommendation.updated_at) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-if="showModal" @close="closeModal">
      <div v-if="isEditing">
        <h3 class="text-lg leading-6 font-medium text-blueGray-700">Editace Doporučení</h3>
        <div class="mt-2">
          <form @submit.prevent="saveRecommendation">
            <div class="grid grid-cols-2 gap-4">
              <div>
                <label for="contact_first_name" class="block text-sm font-medium text-blueGray-600">Jméno kontaktu</label>
                <input type="text" v-model="selectedRecommendation.contact_first_name" id="contact_first_name" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="contact_last_name" class="block text-sm font-medium text-blueGray-600">Příjmení kontaktu</label>
                <input type="text" v-model="selectedRecommendation.contact_last_name" id="contact_last_name" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="contact_email" class="block text-sm font-medium text-blueGray-600">Email kontaktu</label>
                <input type="email" v-model="selectedRecommendation.contact_email" id="contact_email" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="contact_phone" class="block text-sm font-medium text-blueGray-600">Telefon kontaktu</label>
                <input type="tel" v-model="selectedRecommendation.contact_phone" id="contact_phone" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="status" class="block text-sm font-medium text-blueGray-600">Status</label>
                <select v-model="selectedRecommendation.status" id="status" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
                  <option value="active">Aktivní</option>
                  <option value="pending">Nevyřízené</option>
                  <option value="inprogress">V procesu</option>
                  <option value="completed">Dokončeno</option>
                  <option value="rejected">Zamítnuto</option>
                </select>
              </div>
              <div>
                <label for="assigned_to" class="block text-sm font-medium text-blueGray-600">Přiděleno</label>
                <select v-model="selectedRecommendation.assigned_to" id="assigned_to" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
                  <option v-for="coach in coaches" :key="coach.id" :value="coach.id">{{ coach.name }}</option>
                </select>
              </div>
              <div>
                <label for="deal_value" class="block text-sm font-medium text-blueGray-600">Hodnota obchodu</label>
                <input type="number" v-model="selectedRecommendation.deal_value" id="deal_value" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="commission" class="block text-sm font-medium text-blueGray-600">Provize</label>
                <input type="number" v-model="selectedRecommendation.commission" id="commission" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
            </div>
            <div class="mt-4">
              <label for="new_note" class="block text-sm font-medium text-blueGray-600">Nová poznámka</label>
              <textarea v-model="newNote" id="new_note" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm"></textarea>
              <button type="button" @click="addNote" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 mt-2 bg-lightBlue-600 text-base font-medium text-white hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500 sm:text-sm">
                Přidat poznámku
              </button>
            </div>
            <div class="mt-4">
              <button type="submit" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-lightBlue-600 text-base font-medium text-white hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500 sm:text-sm">
                Aktualizovat
              </button>
            </div>
          </form>
          <div class="mt-4">
            <h4 class="text-md leading-6 font-medium text-blueGray-700">Poznámky</h4>
            <ul class="list-disc list-inside">
              <li v-for="(note, index) in sortedNotesHistory(selectedRecommendation.notesHistory)" :key="index">{{ note.timestamp }} - {{ note.note }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="text-lg leading-6 font-medium text-blueGray-700">Podrobnosti Doporučení</h3>
        <div class="mt-2">
          <div class="grid grid-cols-2 gap-4">
            <div>
              <p><strong>Jméno uživatele:</strong> {{ selectedRecommendation.user_name }}</p>
              <p><strong>Email kontaktu:</strong> {{ selectedRecommendation.contact_email }}</p>
              <p><strong>Status:</strong> {{ selectedRecommendation.status }}</p>
              <p><strong>Hodnota obchodu:</strong> {{ selectedRecommendation.deal_value || 'No Value' }}</p>
              <p><strong>Poznámky:</strong></p>
              <ul class="list-disc list-inside">
                <li v-for="(note, index) in sortedNotesHistory(selectedRecommendation.notesHistory)" :key="index">{{ note.timestamp }} - {{ note.note }}</li>
              </ul>
            </div>
            <div>
              <p><strong>Jméno kontaktu:</strong> {{ selectedRecommendation.contact_first_name }} {{ selectedRecommendation.contact_last_name }}</p>
              <p><strong>Telefon kontaktu:</strong> {{ selectedRecommendation.contact_phone }}</p>
              <p><strong>Přiděleno:</strong> {{ getCoachName(selectedRecommendation.assigned_to) }}</p>
              <p><strong>Provize:</strong> {{ selectedRecommendation.commission }}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/Modal.vue';
import backgroundImage from '@/assets/img/background.jpg';

export default {
  name: 'CoachDashboard',
  components: { Modal },
  data() {
    return {
      user: {},
      recommendations: [],
      coaches: [],
      selectedRecommendation: null,
      newNote: '',
      isEditing: false,
      showModal: false,
      activeFilter: {
        status: 'active'
      },
      backgroundImage
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.get(`${apiUrl}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.user = response.data;
      }).catch(error => {
        console.error('Failed to load user data:', error);
      });

      axios.get(`${apiUrl}/api/recommendations/all`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.recommendations = response.data.map(rec => ({
          ...rec,
          showNotes: false,
          notesHistory: rec.notes ? rec.notes.split('\n').map(note => {
            const [timestamp, ...noteText] = note.split(' - ');
            return { timestamp, note: noteText.join(' - ') };
          }) : []
        }));
      }).catch(error => {
        console.error('Failed to load recommendations:', error);
      });

      axios.get(`${apiUrl}/api/recommendations/coaches`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.coaches = response.data;
      }).catch(error => {
        console.error('Failed to load coaches:', error);
      });
    },
    truncateNotes(notesHistory) {
      if (notesHistory.length === 0) return '';
      const lastNote = notesHistory[notesHistory.length - 1];
      return lastNote.note.length > 30 ? lastNote.note.substring(0, 30) + '...' : lastNote.note;
    },
    editRecommendation(recommendation) {
      this.selectedRecommendation = { ...recommendation };
      this.isEditing = true;
      this.showModal = true;
    },
    viewRecommendation(recommendation) {
      this.selectedRecommendation = { ...recommendation };
      this.isEditing = false;
      this.showModal = true;
    },
    addNote() {
      if (this.newNote.trim() !== '') {
        const timestamp = this.formatTimestamp(new Date());
        this.selectedRecommendation.notesHistory.push({ timestamp, note: this.newNote });
        this.selectedRecommendation.notes += `\n${timestamp} - ${this.newNote}`;
        this.newNote = '';
      }
    },
    saveRecommendation() {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.put(`${apiUrl}/api/recommendations/${this.selectedRecommendation.id}`, this.selectedRecommendation, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(() => {
        const index = this.recommendations.findIndex(r => r.id === this.selectedRecommendation.id);
        if (index !== -1) {
          this.recommendations.splice(index, 1, this.selectedRecommendation);
        }
        this.selectedRecommendation = null;
        this.showModal = false;
      }).catch(error => {
        console.error('Failed to update recommendation:', error);
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('cs-CZ', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    formatTimestamp(date) {
      return new Date(date).toLocaleString('cs-CZ', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    translateStatus(status) {
      const statuses = {
        pending: 'Nevyřízené',
        inprogress: 'V procesu',
        completed: 'Dokončeno',
        rejected: 'Zamítnuto',
        archive: 'Archivováno'
      };
      return statuses[status] || status;
    },
    closeModal() {
      this.showModal = false;
      this.selectedRecommendation = null;
    },
    getCoachName(coachId) {
      const coach = this.coaches.find(c => c.id === coachId);
      return coach ? coach.name : 'Nezadáno';
    },
    sortedNotesHistory(notesHistory) {
      return notesHistory.slice().sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  },
  computed: {
    filteredRecommendations() {
      if (this.activeFilter.status === 'active') {
        return this.recommendations.filter(r => r.assigned_to === this.user.id && r.status !== 'archive');
      } else if (this.activeFilter.status) {
        return this.recommendations.filter(r => r.assigned_to === this.user.id && r.status === this.activeFilter.status);
      } else {
        return this.recommendations.filter(r => r.assigned_to === this.user.id);
      }
    },
    sortedNotes() {
      return this.selectedRecommendation ? this.sortedNotesHistory(this.selectedRecommendation.notesHistory) : [];
    }
  }
}
</script>

<style scoped>
.bg-blueGray-50 {
  background-color: #f8fafc;
}
.text-blueGray-500 {
  color: #6b7280;
}
.bg-lightBlue-600 {
  background-color: #3b82f6;
}
.hover\:bg-lightBlue-700:hover {
  background-color: #1e40af;
}
.text-lightBlue-600 {
  color: #3b82f6;
}
.hover\:underline:hover {
  text-decoration: underline;
}
.bg-cover {
  background-size: cover;
  background-position: center;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
</style>
