<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
    <div class="px-6">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 flex justify-center">
          <div class="relative">
            <img
              alt="..."
              :src="user.image ? user.image : team2"
              class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
            />
          </div>
        </div>
        <div class="w-full px-4 text-center mt-20">
          <div class="flex justify-center py-4 lg:pt-4 pt-8">
            <div class="mr-4 p-3 text-center">
              <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">{{ stats.active_recommendations }}</span>
              <span class="text-sm text-blueGray-400">Aktivní doporučení</span>
            </div>
            <div class="lg:mr-4 p-3 text-center">
              <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">{{ stats.current_commission }}</span>
              <span class="text-sm text-blueGray-400">Aktuální provize</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-12">
        <h3 class="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">{{ user.first_name }} {{ user.last_name }}</h3>
        <div class="mb-2 text-blueGray-600 mt-10">
          <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
          {{ user.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  props: {
    user: Object,
    stats: Object
  },
  data() {
    return {
      team2,
    };
  },
};
</script>

<style scoped>
.bg-blueGray-50 {
  background-color: #f8fafc;
}
.text-blueGray-500 {
  color: #6b7280;
}
.text-blueGray-600 {
  color: #4b5563;
}
</style>
