<template>
  <footer class="footer">
    <p class="text-gray-600">© 2024 Geny pro život</p>
    <p class="text-gray-600">Na webu jsou pouze technická cookies nutná k provozu.</p>
    <router-link to="/privacy" class="text-blue-600 hover:underline">Podmínky ochrany osobních údaj</router-link>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  text-align: center;
  padding: 0.25rem 0; /* Menší padding pro nižší footer */
  margin-top: auto;
  width: 100%;
}
</style>