<template>
  <div class="min-h-screen bg-cover flex flex-col" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="w-full flex justify-between items-center px-4 py-4 bg-transparent absolute top-0 left-0 right-0">
      <h1 class="text-3xl font-extrabold text-center w-full text-blueGray-700">Admin Dashboard</h1>
    </div>
    <div class="flex flex-col justify-start sm:px-6 lg:px-8 flex-grow mt-20">
      <div class="sm:mx-auto sm:w-full sm:max-w-7xl">
        <div class="relative mb-4">
          <p class="text-center text-blueGray-600">
            Vítejte, {{ user.name ? user.name.split(' ')[0] : '' }}
            <router-link to="/profile" class="text-lightBlue-600 hover:underline">(Můj profil)</router-link>
          </p>
        </div>
        <div class="w-full">
          <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
            <RecommendationsTable :recommendations="recommendations" :coaches="coaches" @edit-recommendation="editRecommendation" @view-recommendation="viewRecommendation"/>
          </div>
          <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
            <CommissionsTable :commissions="filteredCommissions" @approve-commission="approveCommission" @cancel-commission="cancelCommission"/>
          </div>
        </div>
      </div>
    </div>

    <Modal v-if="showModal" @close="closeModal">
      <div v-if="isEditing">
        <h3 class="text-lg leading-6 font-medium text-blueGray-700">Editace Doporučení</h3>
        <div class="mt-2">
          <form @submit.prevent="saveRecommendation">
            <div class="grid grid-cols-2 gap-4">
              <div>
                <label for="contact_first_name" class="block text-sm font-medium text-blueGray-600">Jméno kontaktu</label>
                <input type="text" v-model="selectedRecommendation.contact_first_name" id="contact_first_name" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="contact_last_name" class="block text-sm font-medium text-blueGray-600">Příjmení kontaktu</label>
                <input type="text" v-model="selectedRecommendation.contact_last_name" id="contact_last_name" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="contact_email" class="block text-sm font-medium text-blueGray-600">Email kontaktu</label>
                <input type="email" v-model="selectedRecommendation.contact_email" id="contact_email" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="contact_phone" class="block text-sm font-medium text-blueGray-600">Telefon kontaktu</label>
                <input type="tel" v-model="selectedRecommendation.contact_phone" id="contact_phone" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="status" class="block text-sm font-medium text-blueGray-600">Status</label>
                <select v-model="selectedRecommendation.status" id="status" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
                  <option value="active">Aktivní</option>
                  <option value="pending">Nevyřízené</option>
                  <option value="inprogress">V procesu</option>
                  <option value="completed">Dokončeno</option>
                  <option value="rejected">Zamítnuto</option>
                  <option value="archive">Archivováno</option>
                </select>
              </div>
              <div>
                <label for="assigned_to" class="block text-sm font-medium text-blueGray-600">Přiděleno</label>
                <select v-model="selectedRecommendation.assigned_to" id="assigned_to" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
                  <option v-for="coach in coaches" :key="coach.id" :value="coach.id">{{ coach.name }}</option>
                </select>
              </div>
              <div>
                <label for="deal_value" class="block text-sm font-medium text-blueGray-600">Hodnota obchodu</label>
                <input type="number" v-model="selectedRecommendation.deal_value" @input="calculateCommission" id="deal_value" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              </div>
              <div>
                <label for="commission" class="block text-sm font-medium text-blueGray-600">Provize</label>
                <input type="number" v-model="selectedRecommendation.commission" id="commission" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" readonly />
              </div>
            </div>
            <div class="mt-4">
              <label for="new_note" class="block text-sm font-medium text-blueGray-600">Nová poznámka</label>
              <textarea v-model="newNote" id="new_note" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-blueGray-300 rounded-md shadow-sm focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm"></textarea>
              <button type="button" @click="addNote" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 mt-2 bg-lightBlue-600 text-base font-medium text-white hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500 sm:text-sm">
                Přidat poznámku
              </button>
            </div>
            <div class="mt-4">
              <button type="submit" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-lightBlue-600 text-base font-medium text-white hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500 sm:text-sm">
                Aktualizovat
              </button>
            </div>
          </form>
          <div class="mt-4">
            <h4 class="text-md leading-6 font-medium text-blueGray-700">Poznámky</h4>
            <ul class="list-disc list-inside">
              <li v-for="(note, index) in sortedNotes" :key="index">{{ note.timestamp }} - {{ note.note }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="text-lg leading-6 font-medium text-blueGray-700">Podrobnosti Doporučení</h3>
        <div class="mt-2">
          <div class="grid grid-cols-2 gap-4">
            <div>
              <p><strong>Jméno uživatele:</strong> {{ selectedRecommendation.user_name }}</p>
              <p><strong>Email kontaktu:</strong> {{ selectedRecommendation.contact_email }}</p>
              <p><strong>Status:</strong> {{ selectedRecommendation.status }}</p>
              <p><strong>Hodnota obchodu:</strong> {{ selectedRecommendation.deal_value || 'No Value' }}</p>
              <p><strong>Poznámky:</strong></p>
              <ul class="list-disc list-inside">
                <li v-for="(note, index) in sortedNotes" :key="index">{{ note.timestamp }} - {{ note.note }}</li>
              </ul>
            </div>
            <div>
              <p><strong>Jméno kontaktu:</strong> {{ selectedRecommendation.contact_first_name }} {{ selectedRecommendation.contact_last_name }}</p>
              <p><strong>Telefon kontaktu:</strong> {{ selectedRecommendation.contact_phone }}</p>
              <p><strong>Přiděleno:</strong> {{ getCoachName(selectedRecommendation.assigned_to) }}</p>
              <p><strong>Provize:</strong> {{ selectedRecommendation.commission }}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import RecommendationsTable from '@/components/Cards/RecommendationsTable.vue';
import CommissionsTable from '@/components/Cards/CommissionsTable.vue';
import Modal from '@/components/Modal.vue';
import backgroundImage from '@/assets/img/background.jpg';

export default {
  name: 'AdminDashboard',
  components: { RecommendationsTable, CommissionsTable, Modal },
  data() {
    return {
      user: {},
      recommendations: [],
      commissions: [],
      coaches: [],
      selectedRecommendation: null,
      newNote: '',
      isEditing: false,
      showModal: false,
      activeFilter: {
        status: 'active'
      },
      backgroundImage
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.get(`${apiUrl}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.user = response.data;
      }).catch(error => {
        console.error('Failed to load user data:', error);
      });

      axios.get(`${apiUrl}/api/recommendations/all`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.recommendations = response.data.map(rec => ({
          ...rec,
          showNotes: false,
          notesHistory: rec.notes ? rec.notes.split('\n').map(note => {
            const [timestamp, ...noteText] = note.split(' - ');
            return { timestamp, note: noteText.join(' - ') };
          }) : []
        }));
      }).catch(error => {
        console.error('Failed to load recommendations:', error);
      });

      axios.get(`${apiUrl}/api/recommendations/coaches`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.coaches = response.data;
      }).catch(error => {
        console.error('Failed to load coaches:', error);
      });

      axios.get(`${apiUrl}/api/commissions/all`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.commissions = response.data;
      }).catch(error => {
        console.error('Failed to load commissions:', error);
      });
    },
    editRecommendation(recommendation) {
      this.selectedRecommendation = { ...recommendation };
      this.isEditing = true;
      this.showModal = true;
    },
    viewRecommendation(recommendation) {
      this.selectedRecommendation = { ...recommendation };
      this.isEditing = false;
      this.showModal = true;
    },
    saveRecommendation() {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      // Combine notesHistory into a single string
      this.selectedRecommendation.notes = this.selectedRecommendation.notesHistory.map(note => `${note.timestamp} - ${note.note}`).join('\n');

      axios.put(`${apiUrl}/api/recommendations/${this.selectedRecommendation.id}`, this.selectedRecommendation, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(() => {
        this.fetchData();
        this.showModal = false;
      }).catch(error => {
        console.error('Failed to save recommendation:', error);
      });
    },
    addNote() {
      if (this.newNote.trim() !== '') {
        const timestamp = new Date().toLocaleString('cs-CZ', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
        this.selectedRecommendation.notesHistory.unshift({ timestamp, note: this.newNote });
        this.saveRecommendation(); // Save recommendation after adding note
        this.newNote = '';
      }
    },
    closeModal() {
      this.showModal = false;
    },
    getCoachName(coachId) {
      const coach = this.coaches.find(c => c.id === coachId);
      return coach ? coach.name : 'Nezadáno';
    },
    approveCommission(commissionId) {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.put(`${apiUrl}/api/commissions/approve`, { id: commissionId, status: 'approved' }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(() => {
        const commission = this.commissions.find(c => c.id === commissionId);
        if (commission) {
          commission.commission_status = 'approved';
        }
      }).catch(error => {
        console.error('Failed to approve commission:', error);
      });
    },
    cancelCommission(commissionId) {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.put(`${apiUrl}/api/commissions/cancel`, { id: commissionId, status: 'pending' }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(() => {
        const commission = this.commissions.find(c => c.id === commissionId);
        if (commission) {
          commission.commission_status = 'pending';
        }
      }).catch(error => {
        console.error('Failed to cancel commission:', error);
      });
    },
    calculateCommission() {
      // Assuming the commission rate is 10% of the deal value
      if (this.selectedRecommendation.deal_value) {
        this.selectedRecommendation.commission = (this.selectedRecommendation.deal_value * 0.10).toFixed(2);
      }
    }
  },
  computed: {
    filteredCommissions() {
      return this.commissions.filter(c => c.status === 'completed');
    },
    sortedNotes() {
      return [...this.selectedRecommendation.notesHistory].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  }
}
</script>

<style scoped>
.bg-blueGray-50 {
  background-color: #f8fafc;
}
.text-blueGray-500 {
  color: #6b7280;
}
.bg-lightBlue-600 {
  background-color: #3b82f6;
}
.hover\:bg-lightBlue-700:hover {
  background-color: #1e40af;
}
.text-lightBlue-600 {
  color: #3b82f6;
}
.hover\:underline:hover {
  text-decoration: underline;
}
.bg-cover {
  background-size: cover;
  background-position: center;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
</style>
