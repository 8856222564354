<template>
  <div v-if="isOpen" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <div class="mt-2">
                <!-- Sekce Dodavatel -->
                <h4 class="text-lg font-medium leading-6 text-gray-900">Dodavatel</h4>
                <p class="text-sm text-gray-500">
                  <strong>{{ user.first_name }} {{ user.last_name }}</strong><br>
                  Email: {{ user.email }}<br>
                  Telefon: {{ user.phone }}<br>
                  Firma: {{ user.companyName }}<br>
                  Adresa: {{ user.address }}<br>
                  IČ: {{ user.ico || '(nevyplněno)' }}<br>
                  DIČ: {{ user.dic || '(nevyplněno)' }}<br>
                  Spisová značka: {{ user.companyRegistration || '(nevyplněno)' }}
                </p>
                <!-- Sekce Odběratel -->
                <h4 class="mt-6 text-lg font-medium leading-6 text-gray-900">Odběratel</h4>
                <p class="text-sm text-gray-500">
                  <strong>Geny pro život s.r.o.</strong><br>
                  IČO: 08094951<br>
                  Spisová značka: C 312910 vedená u Městského soudu v Praze<br>
                  Den zápisu: 17. dubna 2019<br>
                  Sídlo: Holečkova 789/49, Smíchov, 150 00 Praha 5
                </p>
                <h4 class="mt-6 text-lg font-medium leading-6 text-gray-900">Podklady pro vystavení dokumentu</h4>
                <p class="text-sm text-gray-500">Podklady pro vystavení dokumentu dle následujících údajů:</p>
                <table class="min-w-full divide-y divide-gray-200 mt-4 mb-4">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jméno klienta</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Částka</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Datum vystavení</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="data in invoiceData" :key="data.id">
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ data.client_name }}</td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ data.amount }} Kč</td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ new Date().toLocaleDateString('cs-CZ') }}</td>
                    </tr>
                  </tbody>
                </table>
                <p class="text-sm text-gray-500">Celkem k vystavení: {{ invoiceData.reduce((sum, data) => sum + parseFloat(data.amount), 0) }} Kč</p>
                <p class="text-sm text-gray-500 mt-4">Prosím, vystavte fakturu na "Zprostředkování obchodu" na firmu Geny pro život s.r.o. a zašlete ji s touto přílohou na email: <a href="mailto:mam@genyprozivot.cz" class="text-blue-500">mam@genyprozivot.cz</a><br>Splatnost faktury je 14 dní.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="printInvoice" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm">
            Tisknout dokument
          </button>
          <button @click="downloadInvoice" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Stáhnout dokument
          </button>
          <button @click="$emit('close')" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Zavřít
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import RobotoRegular from '@/assets/fonts/Roboto-Regular.ttf.base64';
import RobotoBold from '@/assets/fonts/Roboto-Bold.ttf.base64';
import logo from '@/assets/logo/logo.js';
import axios from 'axios';

export default {
  name: 'InvoiceModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    invoiceData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        companyName: '',
        address: '',
        ico: '',
        dic: '',
        companyRegistration: ''
      }
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.get(`${apiUrl}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.user = response.data;
      }).catch(error => {
        console.error('Nepodařilo se načíst data uživatele:', error);
      });
    },
    generatePDF() {
      const doc = new jsPDF();

      // Přidání fontů
      doc.addFileToVFS('Roboto-Regular.ttf', RobotoRegular);
      doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
      doc.addFileToVFS('Roboto-Bold.ttf', RobotoBold);
      doc.addFont('Roboto-Bold.ttf', 'Roboto', 'bold');
      doc.setFont('Roboto');

      // Přidání loga
      doc.addImage(logo, 'PNG', 150, 20, 40, 20);

      // Sekce Dodavatel
      doc.setFontSize(12);
      doc.text('Dodavatel', 20, 20);
      doc.setFontSize(10);
      doc.text(`${this.user.first_name} ${this.user.last_name}`, 20, 30);
      doc.text(`Email: ${this.user.email}`, 20, 35);
      doc.text(`Telefon: ${this.user.phone}`, 20, 40);
      doc.text(`Firma: ${this.user.companyName}`, 20, 45);
      doc.text(`Adresa: ${this.user.address}`, 20, 50);
      doc.text(`IČ: ${this.user.ico || '(nevyplněno)'}`, 20, 55);
      doc.text(`DIČ: ${this.user.dic || '(nevyplněno)'}`, 20, 60);
      doc.text(`Spisová značka: ${this.user.companyRegistration || '(nevyplněno)'}`, 20, 65);

      // Sekce Odběratel
      doc.setFontSize(12);
      doc.text('Odběratel', 20, 80);
      doc.setFontSize(10);
      doc.text('Geny pro život s.r.o.', 20, 90);
      doc.text('IČO: 08094951', 20, 95);
      doc.text('Spisová značka: C 312910 vedená u Městského soudu v Praze', 20, 100);
      doc.text('Den zápisu: 17. dubna 2019', 20, 105);
      doc.text('Sídlo: Holečkova 789/49, Smíchov, 150 00 Praha 5', 20, 110);

      // Podklady pro vystavení dokumentu
      doc.setFontSize(12);
      doc.text('Podklady pro vystavení dokumentu', 20, 130);
      doc.setFontSize(10);
      doc.text('Podklady pro vystavení dokumentu dle následujících údajů:', 20, 135);

      const tableData = this.invoiceData.map((data, index) => [
        index + 1,
        data.client_name,
        data.amount + ' Kč',
        new Date().toLocaleDateString('cs-CZ')
      ]);

      doc.autoTable({
        head: [['#', 'Jméno klienta', 'Částka', 'Datum vystavení']],
        body: tableData,
        startY: 145,
        styles: {
          font: 'Roboto',
          halign: 'left',
        },
        headStyles: {
          font: 'Roboto',
          fontStyle: 'bold',
          halign: 'left'
        },
        columnStyles: {
          2: { cellWidth: 60, halign: 'left' }
        },
      });

      const totalAmount = this.invoiceData.reduce((sum, data) => sum + parseFloat(data.amount), 0);
      doc.text(`Celkem k vystavení: ${totalAmount} Kč`, 20, doc.autoTable.previous.finalY + 10);

      const additionalText = 'Prosím, vystavte fakturu na "Zprostředkování obchodu" na firmu Geny pro život s.r.o. a zašlete ji s touto přílohou na email: mam@genyprozivot.cz\nSplatnost faktury je 14 dní.';
      const lines = doc.splitTextToSize(additionalText, 180);
      doc.text(lines, 20, doc.autoTable.previous.finalY + 20);

      return doc;
    },
    printInvoice() {
      const doc = this.generatePDF();
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    },
    downloadInvoice() {
      const doc = this.generatePDF();
      doc.save('invoice.pdf');
    }
  }
}
</script>

<style scoped>
.invoice {
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.company-details,
.document-details,
.user-details {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
</style>
