<template>
  <div class="min-h-screen bg-gray-100 flex flex-col" :style="{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h1 class="text-3xl font-extrabold text-center w-full text-blueGray-700">Přidat Doporučení</h1>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-lg rounded-lg sm:px-10">
        <form @submit.prevent="addRecommendation" @submit="validateForm" class="space-y-6" novalidate>
          <div>
            <label for="contact_first_name" class="block text-sm font-medium text-blueGray-700">Jméno:</label>
            <div class="mt-1">
              <input type="text" v-model="contact_first_name" required class="appearance-none block w-full px-3 py-2 border border-blueGray-300 rounded-md shadow-sm placeholder-blueGray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              <span v-if="errors.contact_first_name" class="text-red-600 text-sm">{{ errors.contact_first_name }}</span>
            </div>
          </div>
          <div>
            <label for="contact_last_name" class="block text-sm font-medium text-blueGray-700">Příjmení:</label>
            <div class="mt-1">
              <input type="text" v-model="contact_last_name" required class="appearance-none block w-full px-3 py-2 border border-blueGray-300 rounded-md shadow-sm placeholder-blueGray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              <span v-if="errors.contact_last_name" class="text-red-600 text-sm">{{ errors.contact_last_name }}</span>
            </div>
          </div>
          <div>
            <label for="contact_phone" class="block text-sm font-medium text-blueGray-700">Telefon:</label>
            <div class="mt-1">
              <input type="tel" v-model="contact_phone" required class="appearance-none block w-full px-3 py-2 border border-blueGray-300 rounded-md shadow-sm placeholder-blueGray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              <span v-if="errors.contact_phone" class="text-red-600 text-sm">{{ errors.contact_phone }}</span>
            </div>
          </div>
          <div>
            <label for="contact_email" class="block text-sm font-medium text-blueGray-700">Email:</label>
            <div class="mt-1">
              <input type="email" v-model="contact_email" required class="appearance-none block w-full px-3 py-2 border border-blueGray-300 rounded-md shadow-sm placeholder-blueGray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm" />
              <span v-if="errors.contact_email" class="text-red-600 text-sm">{{ errors.contact_email }}</span>
            </div>
          </div>
          <div>
            <label for="notes" class="block text-sm font-medium text-blueGray-700">Poznámky:</label>
            <div class="mt-1">
              <textarea v-model="notes" required class="appearance-none block w-full px-3 py-2 border border-blueGray-300 rounded-md shadow-sm placeholder-blueGray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm"></textarea>
              <span v-if="errors.notes" class="text-red-600 text-sm">{{ errors.notes }}</span>
            </div>
          </div>
          <div>
            <label for="assigned_to" class="block text-sm font-medium text-blueGray-700">Přidělit kouči:</label>
            <div class="mt-1">
              <select v-model="assigned_to" required class="appearance-none block w-full px-3 py-2 border border-blueGray-300 rounded-md shadow-sm placeholder-blueGray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm">
                <option value="" disabled selected>Vyberte kouče</option>
                <option v-for="coach in coaches" :key="coach.id" :value="coach.id">{{ coach.name }}</option>
              </select>
              <span v-if="errors.assigned_to" class="text-red-600 text-sm">{{ errors.assigned_to }}</span>
            </div>
          </div>
          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lightBlue-600 hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500">Přidat Doporučení</button>
          </div>
        </form>
        <div v-if="error" class="mt-4 text-red-600 text-center">
          {{ error }}
        </div>
        <div v-if="successMessage" class="mt-4 text-green-600 text-center">
          {{ successMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import backgroundImage from '@/assets/img/register_bg_2.png';

export default {
  name: 'AddRecommendation',
  data() {
    return {
      contact_first_name: '',
      contact_last_name: '',
      contact_phone: '',
      contact_email: '',
      notes: '',
      assigned_to: '',
      coaches: [],
      error: null,
      successMessage: null,
      errors: {},
      backgroundImage
    };
  },
  mounted() {
    this.fetchCoaches();
  },
  methods: {
    async fetchCoaches() {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      try {
        const response = await axios.get(`${apiUrl}/api/recommendations/coaches`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.coaches = response.data;
      } catch (error) {
        console.error('Nepodařilo se načíst kouče:', error);
        this.error = 'Nepodařilo se načíst kouče. Zkuste to prosím znovu.';
      }
    },
    async addRecommendation() {
      if (!this.validateForm()) return;

      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      // Přidání časového údaje k poznámkám
      const timestamp = new Date().toLocaleString('cs-CZ', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      const notesWithTimestamp = `${timestamp} - ${this.notes}`;

      try {
        await axios.post(`${apiUrl}/api/recommendations/add`, {
          contact_first_name: this.contact_first_name,
          contact_last_name: this.contact_last_name,
          contact_phone: this.contact_phone,
          contact_email: this.contact_email,
          notes: notesWithTimestamp,
          assigned_to: this.assigned_to
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.successMessage = 'Doporučení bylo úspěšně přidáno';
        setTimeout(() => {
          this.$router.push('/admin/user-dashboard');
        }, 2000);
      } catch (error) {
        console.error('Nepodařilo se přidat doporučení:', error);
        this.error = 'Nepodařilo se přidat doporučení. Zkuste to prosím znovu.';
      }
    },
    validateForm() {
      this.errors = {};

      if (!this.contact_first_name) {
        this.errors.contact_first_name = 'Jméno je povinné.';
      }
      if (!this.contact_last_name) {
        this.errors.contact_last_name = 'Příjmení je povinné.';
      }
      if (!this.contact_phone) {
        this.errors.contact_phone = 'Telefon je povinný.';
      }
      if (!this.contact_email) {
        this.errors.contact_email = 'Email je povinný.';
      }
      if (!this.notes) {
        this.errors.notes = 'Poznámky jsou povinné.';
      }
      if (!this.assigned_to) {
        this.errors.assigned_to = 'Vyberte kouče.';
      }

      return Object.keys(this.errors).length === 0;
    }
  }
}
</script>

<style scoped>
/* Explicit styles to ensure button visibility */
button {
  background-color: #3b82f6; /* Notus lightBlue-600 */
  color: white;
}

button:hover {
  background-color: #2563eb; /* Notus lightBlue-700 */
}
</style>