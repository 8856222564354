import { createRouter, createWebHistory } from 'vue-router';

// Importy vašich komponent
import AdminLayout from '../layouts/AdminLayout.vue';
import PublicLayout from '../layouts/PublicLayout.vue';
import AuthLayout from '../layouts/Auth.vue';
import UserDashboard from '../views/user/UserDashboard.vue';
import CoachDashboard from '../views/coach/CoachDashboard.vue';
import AdminDashboard from '../views/admin/AdminDashboard.vue';
import Settings from '../views/admin/Settings.vue';
import Tables from '../views/admin/Tables.vue';
import Maps from '../views/admin/Maps.vue';
import Login from '../views/auth/Login.vue';
import Register from '../views/auth/Register.vue';
import Home from '../views/Home.vue';
import Landing from '../views/Landing.vue';
import Profile from '../views/Profile.vue';
import AddRecommendation from '../components/AddRecommendation.vue';
import ResetPassword from '../components/ResetPassword.vue';
import ResetPasswordToken from '../components/ResetPasswordToken.vue';

const routes = [
  {
    path: '/admin',
    redirect: '/admin/user-dashboard',
    component: AdminLayout,
    children: [
      {
        path: 'user-dashboard',
        component: UserDashboard,
        meta: { requiresRole: 'user' },
      },
      {
        path: 'coach-dashboard',
        component: CoachDashboard,
        meta: { requiresRole: 'coach' },
      },
      {
        path: 'admin-dashboard',
        component: AdminDashboard,
        meta: { requiresRole: 'admin' },
      },
      {
        path: 'settings',
        component: Settings,
        meta: { requiresRole: 'admin' },
      },
      {
        path: 'tables',
        component: Tables,
        meta: { requiresRole: 'admin' },
      },
      {
        path: 'maps',
        component: Maps,
        meta: { requiresRole: 'admin' },
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        component: Login,
      },
      {
        path: 'register',
        component: Register,
      },
      {
        path: 'reset-password',
        component: ResetPassword,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordToken,
      },
    ],
  },
  {
    path: '/',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: Home,
      },
      {
        path: 'landing',
        component: Landing,
      },
      {
        path: 'profile',
        component: Profile,
      }
    ],
  },
  {
    path: '/add-recommendation',
    component: AddRecommendation,
    meta: { requiresRole: 'user' },
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresRole = to.matched.some(record => record.meta.requiresRole);
  const userRole = localStorage.getItem('userRole');

  console.log('Navigating to:', to.path);
  console.log('Required role:', to.meta.requiresRole);
  console.log('User role:', userRole);

  if (requiresRole) {
    if (userRole && to.meta.requiresRole === userRole) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
