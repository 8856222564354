<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-between pt-4">
    <main class="flex-grow mx-auto w-full max-w-3xl text-center px-6">
      <div class="mt-4">
        <h2 class="text-4xl font-extrabold text-gray-900 mb-4 font-futura">Vítejte v affiliate programu Geny pro život</h2>
      </div>
      <div class="mt-4">
        <p class="text-gray-600 leading-relaxed">
          Jsme rádi, že se chcete připojit k našemu affiliate programu. Jako naši spokojení klienti máte nyní možnost pomoci ostatním objevit výhody našich služeb a zároveň získat provize za vaše doporučení.
        </p>
        <p class="mt-6 text-gray-600 leading-relaxed">
          <strong>Jak to funguje?</strong><br>
          <br>
          <strong>Doporučte naše služby</strong> - Sdílejte své pozitivní zkušenosti s našimi rozvojovými programy.<br>
          <strong>Získejte provizi</strong> - Za každé úspěšné doporučení vám vyplatíme provizi.<br>
          <strong>Podpořte ostatní</strong> - Pomozte svým přátelům a známým dosáhnout jejich osobního a profesního růstu.
        </p>
        <p class="mt-6 text-gray-600 leading-relaxed">
          Věříme, že společně můžeme rozšířit povědomí o našich programech a nabídnout jejich benefity širšímu publiku. Děkujeme, že jste součástí komunity Geny pro život.
        </p>
      </div>
      <div class="mt-8 space-y-4 flex flex-col items-center">
        <router-link to="/auth/login" class="w-40 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Přihlásit se
        </router-link>
        <router-link to="/auth/register" class="w-40 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Registrovat se
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.bg-primary {
  background-color: #0d47a1; /* Přizpůsobte tuto barvu podle potřeby */
}

.font-futura {
  font-family: 'Futura', sans-serif; /* Ujistěte se, že máte tuto fontu nainstalovanou a správně importovanou */
}

.min-h-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
</style>