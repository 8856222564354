<template>
  <div class="bg-white shadow rounded-lg p-4">
    <div class="flex justify-between items-center mb-4">
      <h3 class="text-lg font-semibold text-gray-700">Doporučení</h3>
      <div class="flex items-center space-x-2">
        <label class="block text-sm font-medium text-gray-700">Status:</label>
        <select v-model="localActiveFilter.status" @change="updateFilter" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          <option value="active">Aktivní</option>
          <option value="pending">Nevyřízené</option>
          <option value="inprogress">V procesu</option>
          <option value="completed">Dokončeno</option>
          <option value="rejected">Zamítnuto</option>
          <option value="archive">Archivováno</option>
          <option value="">Všechny</option>
        </select>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jméno uživatele</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jméno kontaktu</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email kontaktu</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Přiděleno</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hodnota obchodu</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provize</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Akce</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vytvořeno</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Aktualizováno</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="recommendation in filteredRecommendations" :key="recommendation.id">
            <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.user_name }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_first_name }} {{ recommendation.contact_last_name }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_email }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ translateStatus(recommendation.status) }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ getCoachName(recommendation.assigned_to) }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.deal_value || 'No Value' }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.commission || 'No Commission' }}</td>
            <td class="px-6 py-4 whitespace-nowrap">
              <button @click="$emit('edit-recommendation', recommendation)" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                Edit
              </button>
              <button @click="$emit('view-recommendation', recommendation)" class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                Zobrazit
              </button>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(recommendation.created_at) }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(recommendation.updated_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecommendationsTable',
  props: {
    recommendations: Array,
    coaches: Array,
    activeFilter: Object
  },
  data() {
    return {
      localActiveFilter: { ...this.activeFilter, status: 'active' }
    };
  },
  methods: {
    updateFilter() {
      this.$emit('update:activeFilter', this.localActiveFilter);
    },
    translateStatus(status) {
      const statuses = {
        pending: 'Nevyřízené',
        inprogress: 'V procesu',
        completed: 'Dokončeno',
        rejected: 'Zamítnuto',
        archive: 'Archivováno'
      };
      return statuses[status] || status;
    },
    getCoachName(coachId) {
      const coach = this.coaches.find(c => c.id === coachId);
      return coach ? coach.name : 'Nezadáno';
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('cs-CZ', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
  },
  computed: {
    filteredRecommendations() {
      if (this.localActiveFilter.status === 'active') {
        return this.recommendations.filter(r => r.status !== 'archive');
      } else if (this.localActiveFilter.status) {
        return this.recommendations.filter(r => r.status === this.localActiveFilter.status);
      } else {
        return this.recommendations;
      }
    }
  }
}
</script>