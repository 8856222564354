<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-4xl relative">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Váš profil</h2>
      <button @click="goBack" class="absolute top-0 right-0 mt-4 mr-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">×</button>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-4xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <CardSettings :user="localUser" @updateUser="updateProfile" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CardSettings from '@/components/Cards/CardSettings.vue';

export default {
  name: 'Profile',
  components: {
    CardSettings,
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        companyName: '',
        address: '',
        ico: '',
        dic: '',
        companyRegistration: '',
      },
      localUser: {},
      errors: {}
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.get(`${apiUrl}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.user = response.data;
        this.localUser = { ...response.data }; // Kopie dat pro lokální úpravy
      }).catch(error => {
        console.error('Nepodařilo se načíst data uživatele:', error);
      });
    },
    updateProfile(updatedUser) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.put(`${apiUrl}/api/users/${userId}`, updatedUser, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(() => {
        alert('Profil byl úspěšně aktualizován.');
        this.goBack();
      }).catch(error => {
        console.error('Nepodařilo se aktualizovat profil:', error);
      });
    },
    goBack() {
      this.$router.go(-1); // Redirect to the previous page without saving changes
    }
  }
}
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.text-gray-900 {
  color: #1a202c;
}

.bg-white {
  background-color: #ffffff;
}

.text-sm {
  font-size: 0.875rem;
}

.block {
  display: block;
}

.w-full {
  width: 100%;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sm\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sm\:w-full {
  width: 100%;
}

.sm\:max-w-md {
  max-width: 28rem;
}

.sm\:max-w-4xl {
  max-width: 64rem;
}

.sm\:rounded-lg {
  border-radius: 0.5rem;
}

.sm\:px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.sm\:grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.appearance-none {
  appearance: none;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #d2d6dc;
}

.rounded-md {
  border-radius: 0.375rem;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.placeholder-gray-400 {
  --tw-placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.focus\:outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-indigo-500 {
  --tw-ring-color: #6366f1;
}

.focus\:border-indigo-500 {
  border-color: #6366f1;
}

.text-white {
  color: #ffffff;
}

.bg-indigo-600 {
  background-color: #4f46e5;
}

.hover\:bg-indigo-700:hover {
  background-color: #4338ca;
}

.focus\:ring-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-color: #6366f1;
}
</style>
