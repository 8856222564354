<template>
  <div class="min-h-screen bg-cover flex flex-col" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="w-full flex justify-between items-center px-4 py-4 bg-transparent absolute top-0 left-0 right-0">
      <h1 class="text-3xl font-extrabold text-center w-full text-blueGray-700">Dashboard</h1>
    </div>
    <div class="flex flex-col justify-start sm:px-6 lg:px-8 flex-grow mt-20">
      <div class="sm:mx-auto sm:w-full sm:max-w-7xl">
        <div class="relative mb-4">
          <p class="text-center text-blueGray-600">
            Vítejte, {{ user.name ? user.name.split(' ')[0] : '' }}
            <router-link to="/profile" class="text-lightBlue-600 hover:underline">(Můj profil)</router-link>
          </p>
        </div>
        <div class="w-full">
          <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
            <div class="flex justify-between items-center mb-4">
              <h3 class="text-xl font-semibold text-blueGray-700">Vaše doporučení</h3>
              <div class="flex items-center space-x-2">
                <label class="block text-sm font-medium text-blueGray-700">Status:</label>
                <select v-model="activeFilter.status" class="mt-1 block w-full shadow-sm sm:text-sm border-blueGray-300 rounded-md">
                  <option value="active">Aktivní</option>
                  <option value="pending">Nevyřízené</option>
                  <option value="inprogress">V procesu</option>
                  <option value="completed">Dokončeno</option>
                  <option value="rejected">Zamítnuto</option>
                  <option value="archive">Archivováno</option>
                  <option value="">Všechny</option>
                </select>
              </div>
            </div>
            <div class="mb-4">
              <button @click="goToAddRecommendation" class="bg-lightBlue-600 hover:bg-lightBlue-700 text-white font-bold py-2 px-4 rounded">
                Přidat nové doporučení
              </button>
            </div>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-blueGray-200">
                <thead>
                  <tr>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Jméno kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Email kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Telefon kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Stav</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Provize</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Přiřazeno</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Akce</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-blueGray-200">
                  <tr v-for="recommendation in filteredRecommendations" :key="recommendation.id">
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_first_name }} {{ recommendation.contact_last_name }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_email }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.contact_phone }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ getStatusTranslation(recommendation.status) }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ recommendation.commission }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ getCoachName(recommendation.assigned_to) }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <button @click="viewRecommendation(recommendation)" class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Zobrazit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
            <h3 class="text-xl font-semibold text-blueGray-700 mb-4">Vaše provize</h3>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-blueGray-200">
                <thead>
                  <tr>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Jméno kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Email kontaktu</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Částka</th>
                    <th class="px-6 py-3 bg-blueGray-50 text-left text-xs font-medium text-blueGray-500 uppercase tracking-wider">Zaplaceno</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-blueGray-200">
                  <tr v-for="commission in completedRecommendations" :key="commission.id">
                    <td class="px-6 py-4 whitespace-nowrap">{{ commission.contact_first_name }} {{ commission.contact_last_name }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ commission.contact_email }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ commission.commission }}</td>
                    <td class="px-6 py-4 whitespace-nowrap">{{ getStatusTranslation(commission.commission_status) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button 
              @click="showInvoiceModal" 
              :disabled="!canGenerateInvoice"
              class="bg-lightBlue-600 hover:bg-lightBlue-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Generovat fakturační údaje
            </button>
            <InvoiceModal
              v-if="isInvoiceModalOpen"
              :isOpen="isInvoiceModalOpen"
              :invoiceData="invoiceData"
              @close="isInvoiceModalOpen = false"
              @print="printInvoice"
              @download="downloadInvoice"
            />
            <Modal v-if="selectedRecommendation" @close="selectedRecommendation = null">
              <h2 class="text-2xl font-bold mb-6">Podrobnosti Doporučení</h2>
              <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div class="mb-4">
                  <p><strong>Jméno kontaktu:</strong> {{ selectedRecommendation.contact_first_name }} {{ selectedRecommendation.contact_last_name }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Email kontaktu:</strong> {{ selectedRecommendation.contact_email }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Telefon kontaktu:</strong> {{ selectedRecommendation.contact_phone }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Status:</strong> {{ getStatusTranslation(selectedRecommendation.status) }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Přiřazeno:</strong> {{ getCoachName(selectedRecommendation.assigned_to) }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Hodnota dohody:</strong> {{ selectedRecommendation.deal_value }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Provize:</strong> {{ selectedRecommendation.commission }}</p>
                </div>
                <div class="mb-4">
                  <p><strong>Poznámky:</strong></p>
                  <ul>
                    <li v-for="note in sortedNotesHistory(selectedRecommendation.notesHistory)" :key="note.timestamp">{{ note.timestamp }} - {{ note.note }}</li>
                  </ul>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import InvoiceModal from '@/components/InvoiceModal.vue';
import Modal from '@/components/Modal.vue';
import backgroundImage from '@/assets/img/background.jpg';

export default {
  name: 'UserDashboard',
  components: { InvoiceModal, Modal },
  data() {
    return {
      user: {},
      recommendations: [],
      commissions: [],
      coaches: [],
      invoiceData: [],
      isInvoiceModalOpen: false,
      selectedRecommendation: null,
      activeFilter: {
        status: 'active'
      },
      backgroundImage
    };
  },
  computed: {
    canGenerateInvoice() {
      return this.approvedCommissions.length > 0;
    },
    approvedCommissions() {
      return this.commissions.filter(commission => commission.commission_status === 'approved');
    },
    completedRecommendations() {
      return this.recommendations.filter(recommendation => recommendation.status === 'completed');
    },
    filteredRecommendations() {
      let filtered = this.recommendations;
      if (this.activeFilter.status === 'active') {
        filtered = this.recommendations.filter(r => r.status !== 'archive');
      } else if (this.activeFilter.status) {
        filtered = this.recommendations.filter(r => r.status === this.activeFilter.status);
      }
      return filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.get(`${apiUrl}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.user = response.data;
      }).catch(error => {
        console.error('Nepodařilo se načíst data uživatele:', error);
      });

      axios.get(`${apiUrl}/api/recommendations/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.recommendations = response.data.map(rec => ({
          ...rec,
          notesHistory: rec.notes ? rec.notes.split('\n').map(note => {
            const [timestamp, ...noteText] = note.split(' - ');
            return { timestamp, note: noteText.join(' - ') };
          }) : []
        }));
      }).catch(error => {
        console.error('Nepodařilo se načíst doporučení:', error);
      });

      axios.get(`${apiUrl}/api/commissions/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.commissions = response.data;
      }).catch(error => {
        console.error('Nepodařilo se načíst provize:', error);
      });

      axios.get(`${apiUrl}/api/recommendations/coaches`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.coaches = response.data;
      }).catch(error => {
        console.error('Nepodařilo se načíst kouče:', error);
      });
    },
    getCoachName(coachId) {
      const coach = this.coaches.find(c => c.id === coachId);
      return coach ? coach.name : 'Nepřiřazeno';
    },
    getStatusTranslation(status) {
      const translations = {
        pending: 'Nevyřízené',
        inprogress: 'V procesu',
        completed: 'Dokončeno',
        rejected: 'Zamítnuto',
        archive: 'Archivováno',
        approved: 'Schváleno'
      };
      return translations[status] || status;
    },
    goToAddRecommendation() {
      this.$router.push('/add-recommendation');
    },
    showInvoiceModal() {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.VUE_APP_API_URL;

      axios.get(`${apiUrl}/api/invoices/generate`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.invoiceData = response.data;
        this.isInvoiceModalOpen = true;
      }).catch(error => {
        console.error('Nepodařilo se vygenerovat fakturační údaje:', error);
      });
    },
    viewRecommendation(recommendation) {
      this.selectedRecommendation = { ...recommendation };
    },
    sortedNotesHistory(notesHistory) {
      return notesHistory.slice().sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  }
};
</script>

<style scoped>
.bg-blueGray-50 {
  background-color: #f8fafc;
}
.text-blueGray-500 {
  color: #6b7280;
}
.bg-lightBlue-600 {
  background-color: #3b82f6;
}
.hover\:bg-lightBlue-700:hover {
  background-color: #1e40af;
}
.text-lightBlue-600 {
  color: #3b82f6;
}
.hover\:underline:hover {
  text-decoration: underline;
}
.bg-cover {
  background-size: cover;
  background-position: center;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
</style>
