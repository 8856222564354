// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Import Vuex store
import './assets/styles/index.css'; // Import Tailwind CSS

const app = createApp(App);
app.use(router);
app.use(store); // Použití Vuex store
app.mount('#app');