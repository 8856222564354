<template>
  <div class="min-h-screen flex flex-col">
    <HeaderStats />
    <main class="flex-grow flex items-center justify-center">
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import Footer from "@/components/Footers/Footer.vue";

export default {
  name: "PublicLayout",
  components: {
    HeaderStats,
    Footer
  }
};
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>