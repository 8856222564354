<template>
  <div id="app" class="min-h-screen flex flex-col">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.min-h-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>