<template>
  <div class="max-w-md w-full mx-auto">
    <div class="bg-white py-8 px-6 shadow-lg rounded-lg">
      <h1 class="text-3xl font-extrabold text-center text-gray-900 mb-6">Přihlášení</h1>
      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700 mb-2">Email</label>
          <div>
            <input id="email" name="email" type="email" autocomplete="email" required v-model="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
          </div>
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700 mb-2">Heslo</label>
          <div>
            <input id="password" name="password" type="password" autocomplete="current-password" required v-model="password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
          </div>
        </div>
        <div style="margin-top: 1rem;"></div>
        <div class="mt-4 text-center">
          <button type="submit" class="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Přihlásit se</button>
        </div>
        <div class="mt-4 text-center">
          <router-link to="/auth/reset-password" class="text-blue-600 hover:underline">Zapomněli jste heslo?</router-link>
        </div>
      </form>
      <div v-if="error" class="mt-4 text-red-600 text-center">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: null
    };
  },
  methods: {
    async login() {
      const apiUrl = process.env.VUE_APP_API_URL || import.meta.env.VITE_APP_API_URL;
      console.log('API URL:', apiUrl);
      console.log('Email:', this.email);
      console.log('Password:', this.password);

      try {
        const response = await axios.post(`${apiUrl}/api/users/login`, {
          email: this.email,
          password: this.password
        });
        console.log('Login response:', response.data);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('userRole', response.data.role); // Uložení role do správného klíče
        this.redirectUser(response.data.role);
      } catch (error) {
        console.error('Login failed:', error);
        this.error = 'Přihlášení selhalo. Zkontrolujte své přihlašovací údaje.';
      }
    },
    redirectUser(role) {
      console.log('Redirecting user with role:', role);
      if (role === 'admin') {
        this.$router.push('/admin/admin-dashboard');
      } else if (role === 'coach') {
        this.$router.push('/admin/coach-dashboard');
      } else {
        this.$router.push('/admin/user-dashboard');
      }
    }
  }
}
</script>

<style scoped>
button {
  background-color: #3B82F6; /* Blue */
  color: #FFFFFF; /* White */
  border: none;
  padding: 0.5rem 1rem; /* Snížená výška tlačítka */
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

button:hover {
  background-color: #2563EB; /* Darker blue */
  transform: translateY(-2px);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

input {
  width: calc(100% - 2rem); /* Přidána mezera po stranách */
  padding: 0.5rem 1rem;
  border: 1px solid #D1D5DB; /* Light gray */
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #374151; /* Gray */
  background-color: #FFFFFF; /* White */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #3B82F6; /* Blue */
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
}

label {
  font-weight: 600;
  color: #374151; /* Gray */
  margin-bottom: 0.5rem; /* Přidána mezera mezi štítkem a vstupním polem */
}

h1 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-weight: 700;
  color: #111827; /* Gray 900 */
  text-align: center;
  margin-bottom: 1.5rem; /* 24px */
}

router-link {
  color: #3B82F6; /* Blue */
  font-weight: 500;
  transition: color 0.3s ease;
  display: inline-block; /* Zajištění zarovnání s tlačítkem */
}

router-link:hover {
  color: #2563EB; /* Darker blue */
}

.text-center {
  text-align: center;
}
</style>