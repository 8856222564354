<template>
  <div class="min-h-screen flex flex-col bg-cover relative" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="absolute top-4 right-4">
      <button @click="logout" class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded z-10">Odhlásit se</button>
    </div>
    <HeaderStats class="bg-transparent" />
    <div class="flex-grow relative">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import Footer from "@/components/Footers/Footer.vue";
import backgroundImage from '@/assets/img/background.jpg';

export default {
  name: "AdminLayout",
  components: {
    HeaderStats,
    Footer
  },
  data() {
    return {
      backgroundImage
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('userRole');
      this.$router.push('/auth/login');
    }
  }
};
</script>

<style scoped>
.bg-cover {
  background-size: cover;
  background-position: center;
}
</style>