<template>
  <section class="relative w-full max-w-md bg-white p-8 rounded-lg shadow-md z-10">
    <h2 class="text-center text-3xl font-extrabold text-gray-900">Resetování hesla</h2>
    <form @submit.prevent="sendResetLink" class="mt-8 space-y-6" novalidate>
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
        <div class="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            v-model="email"
            required
            @invalid="showEmailError = true"
            @input="showEmailError = false"
            class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lightBlue-500 focus:border-lightBlue-500 sm:text-sm"
          >
          <span v-if="showEmailError" class="text-red-600 text-sm">Prosím zadejte platnou emailovou adresu.</span>
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lightBlue-600 hover:bg-lightBlue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue-500"
        >
          Odeslat odkaz na reset hesla
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      showEmailError: false
    };
  },
  methods: {
    sendResetLink() {
      if (!this.email) {
        this.showEmailError = true;
        return;
      }
      const apiUrl = process.env.VUE_APP_API_URL;
      axios.post(`${apiUrl}/api/auth/forgot-password`, { email: this.email })
        .then(() => {
          alert('Odkaz na resetování hesla byl odeslán na váš email.');
          this.$router.push('/auth/login'); // Přesměrování na přihlašovací stránku
        })
        .catch(error => {
          console.error('Odeslání odkazu na resetování hesla selhalo:', error);
          alert('Odeslání odkazu na resetování hesla selhalo. Zkuste to prosím znovu.');
        });
    }
  }
}
</script>

<style scoped>
button {
  background-color: #2563eb; /* Default to Notus blue */
  color: #ffffff;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #1d4ed8; /* Slightly lighter blue on hover */
}

input {
  background-color: #f9fafb; /* Light background for input */
}
</style>
