<template>
  <div class="min-h-screen flex flex-col">
    <HeaderStats />
    <main class="flex-grow flex items-center justify-center bg-gray-100 relative">
      <div class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full" :style="`background-image: url('${registerBg2}');`"></div>
      <section class="relative w-full max-w-md bg-white p-8 rounded-lg shadow-md z-10">
        <router-view />
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import Footer from "@/components/Footers/Footer.vue";
import registerBg2 from "@/assets/img/register_bg_2.png";

export default {
  data() {
    return {
      registerBg2,
    };
  },
  components: {
    HeaderStats,
    Footer,
  },
};
</script>

<style scoped>
main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
section {
  width: 100%;
  max-width: 400px;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}
</style>