// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    userRole: '',
    user: {}
  },
  mutations: {
    setUserRole(state, role) {
      state.userRole = role;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    fetchUserRole({ commit }) {
      const userRole = localStorage.getItem('userRole');
      commit('setUserRole', userRole);
    },
    fetchUser({ commit }) {
      const user = {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('userEmail')
      };
      commit('setUser', user);
    }
  },
  getters: {
    userRole: state => state.userRole,
    user: state => state.user
  }
});
