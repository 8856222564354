<template>
    <div class="bg-white shadow rounded-lg p-4">
      <h3 class="text-lg font-semibold text-gray-700 mb-4">Provize</h3>
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jméno kontaktu</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email kontaktu</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provize</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Akce</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="commission in commissions" :key="commission.id">
              <td class="px-6 py-4 whitespace-nowrap">{{ commission.client_name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ commission.contact_email }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ commission.commission || 'No Commission' }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ commission.commission_status }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                <button v-if="commission.status === 'completed' && commission.commission_status !== 'approved'" 
                  @click="$emit('approve-commission', commission.id)" 
                  class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                  Schválit provizi
                </button>
                <button v-if="commission.commission_status === 'approved'" 
                  @click="$emit('cancel-commission', commission.id)" 
                  class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                  Zamítnout
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CommissionsTable',
    props: {
      commissions: Array
    }
  }
  </script>
  