<template>
  <header class="header bg-primary">
    <img src="@/assets/logo/logo.png" alt="Geny pro život Logo" class="logo"/>
  </header>
</template>

<script>
export default {
  name: 'HeaderStats'
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.logo {
  max-height: 50px;
  width: auto;
}
</style>